import React, { FC, useState } from 'react';
import { PageProps } from 'gatsby';
import { Seo, DropBox, PageLayout, FileViewTable, Stepper } from '../components';
import { FileStateType as StateType } from '../typedefs';
import { FlipTwoTone, GetAppTwoTone, HorizontalSplitTwoTone, MoveToInboxOutlined } from '@material-ui/icons/';

console.log(process.env.GATSBY_ENVIRONMENT);

const ConvertionSteps = React.memo(() => (
    <Stepper
        iconsLables={[
            { Icon: MoveToInboxOutlined, label: (<><h3>Step 1</h3><p>Drag & Drop or Browse your desired file in the above box</p></>) },
            { Icon: HorizontalSplitTwoTone, label: (<><h3>Step 2</h3><p>Select the type of file you want to convert</p></>) },
            { Icon: FlipTwoTone, label: (<><h3>Step 3</h3><p>Convert your file</p></>) },
            { Icon: GetAppTwoTone, label: (<><h3>Step 4</h3><p>Download your file</p></>) },
        ]}
    />
))
/////////////////////////////////////////// Functional components ////////////////////////////////////////

const Index: FC<PageProps> = ({ path }) => {
    const [state, setState] = useState<StateType[]>([]);
    const handleFileStateRemove = (id: number) => {
        const newState = state.filter((st) => st.id !== id)
        setState([...newState])
    };

    const addNewFiles = (files: File[]) => {
        files.forEach((file) => {
            state.push({
                id: state.slice(-1)[0] ? (state.slice(-1)[0].id + 1) : 1,
                file: file,
                remoteFileName: null,
                fileUrl: null,
                isFetching: false,
                fetchingAction: null,
                errorMsg: null,
                isError: false,
            })
        })
        setState([...state])
    }

    return (
        <PageLayout path={path} >
            <Seo
                title='Change Format of File Online | Free File Converting Tool | flipmyfile'
                propMetaDescription={`Get your file converted into different formats for free! Change the format of file online with our smart free file conversion tool. FlipMyFile is here to convert all your files online & free.`}
            />
            <DropBox onFileReceive={addNewFiles} />
            <br />
            <ConvertionSteps />
            <br /><br />
            <FileViewTable filesDetailsArray={state} onRowRemove={handleFileStateRemove} />
        </PageLayout>
    )
}

export default Index;